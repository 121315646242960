import { parseURL, withBase } from 'ufo';
import type { ProviderGetImage } from '@nuxt/image';

export const getImage: ProviderGetImage = (src: string, { baseURL = 'https://id-cs.com' } = {}) => {
    const { pathname } = parseURL(src);

    return {
        url: withBase(pathname, baseURL),
    };
};
